import {
    createStyles,
    Text,
    SimpleGrid,
    ActionIcon,
    rem,
    Container,
    Anchor
} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconPhone, IconMessage } from '@tabler/icons-react';
import { responsive_header_height } from '../../constant/Responsive'
import { Webtitle } from '../Webtitle';
const useStyles = createStyles((theme) => ({
    wrapper: {
        margin: 0,
        width: "100%",
        boxSizing: 'border-box',
        borderRadius: theme.radius.md
    },
    description: {
        color: "#444444",
        maxWidth: rem(300),
        margin:"auto",
        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    form: {
        backgroundColor: theme.white,
        padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
    },

    social: {
        color: theme.white,
        '&:hover': {
            color: theme.colors[theme.primaryColor][1],
        },
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,

        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: "#61ce70",
    },
}));

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export default function ContactUs(prop) {
    const { classes } = useStyles();

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
            <Icon size="1.4rem" stroke={1.5} />
        </ActionIcon>
    ));
    const update_padding = () => Object.fromEntries(Object.entries(responsive_header_height).map((e) => [e[0], e[1] + 20]))

    return (
        <Container className={classes.wrapper} maw="100%" pt={update_padding()} pb="0">
            <SimpleGrid cols={1} row={4} spacing={20} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} p="0" w={prop.rwidth} maw="100%" ml="auto" mr="auto">
                <Webtitle title="查詢訂銷"></Webtitle>
            
                <Text className={classes.description} mr="auto" mt="sm" mb={30}> 如需查詢訂購，請聯絡我們</Text>
                <Text className={classes.description} mt="sm" mb={30}>
                    聯絡電話: 98312915
                </Text>
                <Text className={classes.description} mt="sm" mb={30}>
                    Whatsapp: 98312915
                </Text>
                <Text className={classes.description} mt="sm" mb={30}>
                Facebook: <Anchor href="https://www.facebook.com/pages/category/Food-Wholesaler/%E8%B2%93%E5%B1%B1%E7%8E%8B%E6%9C%9D-387798108690714">貓山王朝 </Anchor>
                </Text>
                <Text className={classes.description} mt="sm" mb={30}>
                    地址：  九龍青山道479至479 A號麗昌工廠大廈2樓202 P室
                </Text>
            </SimpleGrid>
        </Container>);
}