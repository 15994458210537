import {
    Title,
    Center,
    Container
}  from '@mantine/core';
export function Webtitle(props){
    return (
        <Container w="100%" maw="100%" p="auto" style={{borderBottom:"2px solid #ffbf00"}}   >
            <Center style={{padding:"20px"}} >
                <Title fz={{xxs:"1rem",xs:"1.25rem"}} miw={"fit-content"} w="fit-content">
                👑{props.title}👑
                </Title>
            </Center>
        </Container>
    );
};

