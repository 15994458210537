import { Card, Image, Title, Badge, Button, Group,AspectRatio } from '@mantine/core';
import homepageImage1 from '../images/logodurian.jpg';

export default function DemoCard(props) {
  return (
    <Card shadow="xxs" padding="lg" radius="md" >
      <Card.Section component="a" >
        <Image
          src={props.src}
          // height={600}
          fit={props.contain_mode}
          // width={props.width}
          // fit="contain"
          alt="Durian"
        />
      </Card.Section>
    </Card>
  );
}