import { Card, Image, Text, Badge, Button, Group, Flex } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import Nav from 'react-bootstrap/Nav';
const img_h = 430
export default function ProductCard(props) {
  let img_list = props.img_list;
  let img_list_card = img_list.map((path) => <Carousel.Slide> 
    <Image mih={0} height={img_h}  fit="contain" 
    src={path}
    alt={path}
  /></Carousel.Slide>)
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder pt="0">
      <Flex direction="column" justify="space-between" m="0" h="100%">

        <Card.Section slideSize="100%">
          {
            (img_list.length > 1) ?
              <Carousel maw={"100%"} mah={"100%"} mx="auto" withIndicators h={"fit-content"} >
                {
                  img_list_card
                }
              </Carousel>
              :
              <Image
                height={img_h}
                src={img_list[0]}
                alt="Norway"
              />
          }
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text weight={500}>{props.title}</Text>
          <Badge color="green" variant="light">
            On Sale
          </Badge>
        </Group>

        <Text size="sm" color="dimmed">
          {props.description}
        </Text>
        <Nav.Link href="contact">
          <Button variant="light" color="green" fullWidth mt="md" radius="md">
            查詢訂銷
          </Button>
        </Nav.Link>
      </Flex>

    </Card>
  );
}