import { useState, useRef } from 'react';
import './HomePage.css'
import { WebParagraph } from "./WebParagraph";
import { Webtitle } from './Webtitle';
import DemoCard from './DemoCard';
import homepageImage1 from '../images/logodurian.jpg';
import homepageImage2 from '../images/alotofdurian.jpg';
import homepageImage3 from '../images/beautifuldurian.jpg';
import homepageImage4 from '../images/moonCake/mcnew1.jpg';
import homepageImage5 from '../images/moonCake/mcn2.jpg';
import homepageImage6 from '../images/moonCake/mcn3.jpg';
import homepageImage7 from '../images/moonCake/mncake3.jpg'
import durian_cake from '../images/durian_cake.jpeg';

import { IconFlag } from '@tabler/icons-react';
import {
    Grid,
    Container,
    Title,
    Button,
    Center
} from '@mantine/core';
import { NavLink } from 'react-bootstrap';

let header_nav_background_style = {
    marginRight: '0',
    transition: 'background-color 0.8s ease',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "fit-content",
    minWidth: "100px"
};

function HomePageContent(props) {

    return (
        <Container w={props.rwidth} maw="100%" mt={{ md: "lg" }}>
            <Webtitle title="熱門產品" />
            <WebParagraph content="現在是香滑榴蓮月餅大促銷！盡情品味頂級的黑刺和貓山王榴蓮月餅，它們融入細膩的月餅皮中，帶給您口感豐富、香味濃郁的美食享受。我們提供多種口味和包裝選擇，方便快捷的線上訂購服務，並提供全港快遞配送。趕快抓住這個難得的促銷機會，讓香滑榴蓮月餅成為您中秋節的美味之選！">
            </WebParagraph>
            <Container mt={{ base: "md" }}>
                <Center>
                    <NavLink href="/contact">
                        <Button color={"green"}>
                            馬上訂購
                        </Button>
                    </NavLink>

                </Center>
            </Container>
            <Container w="100%" maw="100%" mt={{ base: "lg" }}>
                <Grid justify="center">
                    <Grid.Col md={6} lg={4}> <NavLink href="/mooncake"><DemoCard title="榴蓮月餅" src={homepageImage7} contain_mode={"contain"} /></NavLink></Grid.Col>
                    <Grid.Col md={6} lg={4}> <NavLink href="/mooncake"><DemoCard title="榴蓮月餅" src={homepageImage5} contain_mode={"contain"} /></NavLink></Grid.Col>
                    <Grid.Col md={6} lg={4}><NavLink href="/mooncake"><DemoCard title="榴蓮月餅" src={homepageImage6} contain_mode={"contain"} /></NavLink></Grid.Col>
                </Grid>
            </Container>
            <Container w="100%" maw="100%" mt={{ base: "lg" }}>
                <Webtitle title="關於猫山王朝" />
                <WebParagraph content="我們從馬來西亞彭享洲嚴選優質可靠榴槤果泥及月餅，和當地榴槤園合作，每星期由當地直接空運榴槤果泥到香港，專門為各大香港食肆，餐廳，甜品店等供應餡料。
        我們希望為客人提供品質最好，價格最合理的榴槤果泥，並且讓客人獲得最可靠的榴槤果泥供應。我們亦會在貓山王榴槤盛產季節，提供榴槤批發和訂購服務。最新鮮的貓山王榴槤由馬來西亞直接送往香港，客人對我們的榴槤品質皆給予肯定。有意購買者，歡迎隨時聯絡我們。"
                />
                <Container w="100%" maw="100%" mt={{ base: "lg" }}>
                    <Grid justify="center">
                        <Grid.Col md={6} lg={4}><DemoCard title="榴蓮鮮果" src={homepageImage1} contain_mode={"cover"} width={600} /></Grid.Col>
                        <Grid.Col md={6} lg={4}><DemoCard title="榴蓮果泥" src={homepageImage2} contain_mode={"cover"} width={600} /></Grid.Col>
                        <Grid.Col md={6} lg={4}><DemoCard title="榴蓮凍果" src={homepageImage3} contain_mode={"cover"} width={600} /></Grid.Col>
                    </Grid>
                </Container>

            </Container>
        </Container>
    );
}
export default HomePageContent;