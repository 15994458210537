import { createStyles, Container, rem, Flex, Center, Text, SimpleGrid } from '@mantine/core';
import { IconBrandWhatsapp, IconBrandFacebook, IconMail, IconPrinter, IconFlag } from '@tabler/icons-react';
import { useRef } from 'react';
import Nav from 'react-bootstrap/Nav'
const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: rem(0),
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
        background: "#ffffff",
        height: "fit-content"
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.md} ${theme.spacing.md}`,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },
    links: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.lg,
            marginBottom: theme.spacing.sm,
        },
    },
}));

const header_title = "猫山王朝";

export default function PageFooter() {
    const { classes } = useStyles();
    const nav_list = [useRef(null), useRef(null), useRef(null)];
    const navhover = (obj_ref) => {
        // obj_ref.current.style.borderBottom= "3px solid #ffaf00";
    };
    const navhoverexit = (obj_ref) => {
        // obj_ref.current.style.borderBottom= "0px solid rgb(0,0,0,0)";
    };
    return (
        <div className={classes.footer}>
            <Container px={0} py={20} maw="100%">
                <SimpleGrid cols={3} spacing={20} breakpoints={[{ maxWidth: '50rem', cols: 1 }]} w={{xxs:"90%",md:"80%"}} maw="100%" mx="auto" mt={"lg"}>
                    <Flex direction={"row"} maw={"30rem"} justify={"center"} >
                        <Flex direction="column" w={"100%"} justify={"center"} align={"space-around"}>
                            <Center>
                                <div style={{ width: "fit-content", minWidth: "15rem", fontWeight: "800", letterSpacing: "-0.1rem", textAlign: 'center', fontFamily: 'ZCOOL QingKe HuangYou', fontSize: "3em", color: " #ffbf00", margin: "10px", float: "right" }}>
                                    {header_title}
                                </div>
                            </Center>
                        </Flex>
                    </Flex>
                    <Flex direction="row" maw={"30rem"} justify={"center"} align={"center"} h={"fit-content"} gap={4} wrap="wrap">
                        <Container px="0" w={"20rem"}>
                            <Text><IconBrandWhatsapp></IconBrandWhatsapp> +852 98312915</Text>
                        </Container>
                        <Container px="0" w={"20rem"}>
                            <Text><IconMail></IconMail> hangtatmsk@gmail.com</Text>
                        </Container>
                        <Container px="0" w={"20rem"}>
                            <Text><IconPrinter></IconPrinter> 81470126</Text>
                        </Container>
                        <Container px="0" w={"20rem"}>
                            <Nav.Link href="https://www.facebook.com/pages/category/Food-Wholesaler/%E8%B2%93%E5%B1%B1%E7%8E%8B%E6%9C%9D-387798108690714"> <IconBrandFacebook></IconBrandFacebook> 貓山王朝</Nav.Link>
                        </Container>
                    </Flex>
                    <Flex direction="row" maw={"30rem"} justify={"center"} align={"center"} h={"fit-content"} gap={4} wrap="wrap">
                        {/* <Flex direction="column" maw={"30rem"} justify={"center"} align={"flex-start"}> */}
                        <Container px="0" w={"20rem"} onMouseEnter={() => navhover(nav_list[0])} onMouseLeave={() => navhoverexit(nav_list[0])}>
                            <Nav.Link ref={nav_list[0]} href="home" style={{color:"#777777"}}>首頁</Nav.Link>
                        </Container>
                        <Container px="0" w={"20rem"} onMouseEnter={() => navhover(nav_list[1])} onMouseLeave={() => navhoverexit(nav_list[1])}>
                            <Nav.Link ref={nav_list[1]} href="contact" style={{color:"#777777"}}>查詢訂銷</Nav.Link>
                        </Container>
                        <Container px="0" w={"20rem"} onMouseEnter={() => navhover(nav_list[2])} onMouseLeave={() => navhoverexit(nav_list[2])}>
                            <Nav.Link ref={nav_list[2]} href="product" style={{color:"#777777"}}>零售及批發產品</Nav.Link>
                        </Container>

                    </Flex>
                </SimpleGrid>

            </Container>

        </div>
    );
}