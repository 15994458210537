import ProductGallery from './ProductGallery';
import { rwidth } from '../../constant/Responsive'
import { Carousel } from '@mantine/carousel';
import { Grid, Flex, Image, Text, Container, Center, Box, Paper, Button } from '@mantine/core';
import { Webtitle } from "../Webtitle.jsx"
import { WebParagraph } from '../WebParagraph';
import product_info from '../../constant/ProductInfo'
import { NavLink } from 'react-bootstrap';
// const img_size = 470

function SpecificProductPage(props) {
    //props.product_id
    let product = props.product;
    let img_list = product.image
    let img_list_card = img_list.map((path) => <Carousel.Slide >
        <Center >
            <Image 
                height={600}
                fit='contain'
                src={path}
                alt={path} my="auto" />
        </Center>
    </Carousel.Slide>)
    return (
        <Container mah="100%" w="100%" maw={{xxs:"100%",sm:"80%"}} h={"100%"}>
            <Paper shadow="xl" p="md" mah="100%" h="100%">
                <Grid justify='center' mt="100px" mah="100%" h="100%"  >
                    <Grid.Col span={12} md={6} mah="100%" h="100%" >
                        <Flex direction="column" mah="100%" h="100%" maw="100%" w="100%" justify={"space-around"}>
                            <Carousel w={"fit-content"} maw={"100%"} mah={"200%"} mx="auto" withIndicators h={"100%"} >
                                {
                                    img_list_card
                                }
                            </Carousel>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={12} md={6} mah="100%" h="100%">
                        <Flex w={"100%"} direction={"column"} align="center" p="auto" justify={"space-between"} mih={{ xxs: "fit-content", sm: '30vh', md: '60vh' }}>
                            <Flex w={"100%"} direction={"column"} align="center" p="auto" h={"100%"}>
                                <Webtitle title={product.title}></Webtitle>
                                <Container w="100%" h="100%" my="lg">
                                    <Text size="sm" color="dimmed">
                                        {product.detail_description}
                                    </Text>
                                </Container>
                            </Flex>
                            <Container h="100%" my="lg">
                                <NavLink href="/contact">
                                    <Button >
                                        查詢訂銷
                                    </Button>
                                </NavLink>
                            </Container>
                        </Flex>

                    </Grid.Col>
                </Grid>
            </Paper>
        </Container>

    );
}
export default SpecificProductPage;