import { Menu, Burger, createStyles } from '@mantine/core';
import Nav from 'react-bootstrap/Nav';

import { useState } from 'react';
const useStyles = createStyles((theme) => ({
    item: {
        '&[data-hovered]': {
            backgroundColor: "#ffe066",
            color: theme.grey,
        },
    },
}));


export default function Dropdown(props) {
    const [opened, setOpened] = useState(false);
    const { classes } = useStyles();
    return (
        <Menu classNames={classes} shadow="md" width={"100%"} >
            <Menu.Target>
                <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="lg"
                    color={"#f6c915"}
                    mr="xl"
                />
            </Menu.Target>
            <Menu.Dropdown bg="#ffd65c" style={{ border: "0px" }}>
                <Nav.Link href="home" ><Menu.Item bg="#ffd65c" >首頁</Menu.Item></Nav.Link>
                <Nav.Link href="contact"><Menu.Item bg="#ffd65c">查詢訂銷</Menu.Item></Nav.Link>
                <Nav.Link href="product"><Menu.Item bg="#ffd65c" >零售及批發產品</Menu.Item></Nav.Link>
            </Menu.Dropdown>
        </Menu>
    );
}